<template>
  <div>
    <a-card title="正式工工资结算">
      <a-row gutter="16">
        <div style="float: right">
          <a-button type="primary" icon="plus" @click="createPayment()"
            >创建工资单</a-button
          >
        </div>
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.search"
            placeholder="编号, 名称, 备注"
            allowClear
            @pressEnter="search"
          />
        </a-col>

        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-select
            placeholder="员工选择"
            v-model="searchForm.employee"
            allowClear
            @change="search"
            style="width: 200px"
          >
            <a-select-option
              v-for="option in employeeItems"
              :key="option.id"
              :value="option.id"
            >
              <a-tooltip :title="option.name">
                <span>{{ option.name }}</span>
              </a-tooltip>
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-month-picker
            placeholder="选择月份"
            format="YYYY-MM"
            @change="handleMonthChange"
          >
          </a-month-picker>
        </a-col>

        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>
      <a-descriptions
        :column="{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }"
        style="text-align: center"
      >
        <a-descriptions-item label="总支出"
          ><span class="total"
            >{{ totalPaidAmount.toFixed(2) }}元</span
          ></a-descriptions-item
        >
      </a-descriptions>
      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
          bordered
        >
        <template slot="image" slot-scope="value, record">
            <div>
              <template
                v-if="
                  record.payment_images_item &&
                  record.payment_images_item.length
                "
              >
                <img
                  :src="record.payment_images_item[0].image"
                  alt="Preview"
                  @click="showImages(record.payment_images_item)"
                  style="
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    cursor: pointer;
                  "
                  loading="lazy"
                />
              </template>
              <template v-else>
                <span>暂无图片</span>
              </template>
            </div>
          </template>


          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger" :disabled="item.is_void">{{
                  item.is_void ? "已作废" : "作废"
                }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>

          <template slot="overtimePay" slot-scope="text, record">
            <!-- 可以用 <a>标签、<span>、<a-button>... 随你 -->
            <a
              @click="viewPaymentOrders(record, 'Attendance')"
              style="color: blue; cursor: pointer"
            >
              {{ text }}
            </a>
          </template>

          <template slot="absenceDeductions" slot-scope="text, record">
            <!-- 可以用 <a>标签、<span>、<a-button>... 随你 -->
            <a
              @click="viewPaymentOrders(record, 'Absence')"
              style="color: blue; cursor: pointer"
            >
              {{ text }}
            </a>
          </template>
        </a-table>
      </a-row>
    </a-card>

    <payment-details-modal v-model="visible" :items="tableData" />
    <!-- 图片浏览模态框 -->
    <a-modal
      title="Image Gallery"
      :visible="isModalVisible"
      @cancel="handleCancel"
      width="60%"
      footer="null"
    >
      <a-carousel effect="fade" v-model="currentImageIndex">
        <div v-for="(img, index) in images" :key="index">
          <img
            :src="img.image"
            style="width: 100%; height: auto"
            :alt="img.name"
          />
        </div>
      </a-carousel>
    </a-modal>
  </div>
</template>

<script>
import { salaryRecordsList } from "@/api/hrms";
import NP from "number-precision";
import { employeeOption } from "@/api/option";
import { SalaryRecordVoid } from "@/api/hrms";
export default {
  name: "PaymentRecord",
  components: {
    PaymentDetailsModal: () => import("./PaymentDetailsModal.vue"),
  },
  data() {
    return {
      //  图片相关
      isModalVisible: false,
      images: [],
      currentImageIndex: 0,
      employeeItems: [],
      tableData: [],
      columns: [
        {
          title: "姓名",
          dataIndex: "employee_name", // 根据后端实际字段，或自定义
          key: "employee_name",
        },
        {
          title: "日期",
          dataIndex: "payment_date", // 根据后端实际字段，或自定义
          key: "payment_date",
        },
        {
          title: "应发数",
          children: [
            {
              title: "基本工资",
              dataIndex: "base_salary", // 后端返回 "base_salary"
              key: "base_salary",
              // 对齐方式
              align: "center",
            },
            {
              title: "加班工资",
              dataIndex: "overtime_pay", // 后端返回 "overtime_pay"
              key: "overtime_pay",
              align: "center",
              scopedSlots: { customRender: "overtimePay" },
            },
            {
              title: "应发合计",
              key: "yingfa_total",
              align: "center",
              // 自定义渲染，计算 "基本工资 + 补助工资"
              customRender: (text, record) => {
                const base = parseFloat(record.base_salary || 0);
                const overtime = parseFloat(record.overtime_pay || 0);
                return (base + overtime).toFixed(2);
              },
            },
          ],
        },
        {
          title: "应扣数",
          children: [
            {
              title: "缺勤",
              dataIndex: "absence_deductions",
              key: "absence_deductions",
              align: "center",
              scopedSlots: { customRender: "absenceDeductions" },
            },
            {
              title: "社保",
              dataIndex: "social_security",
              key: "social_security",
              align: "center",
            },
            {
              title: "公积金",
              dataIndex: "provident_fund",
              key: "provident_fund",
              align: "center",
            },
            {
              title: "个税",
              dataIndex: "personal_tax",
              key: "personal_tax",
              align: "center",
            },
            {
              title: "其他",
              dataIndex: "other_amount",
              key: "other_amount",
              align: "center",
            },
            {
              title: "合计",
              key: "yingkou_total",
              align: "center",
              customRender: (text, record) => {
                const abs = parseFloat(record.absence_deductions || 0);
                const ss = parseFloat(record.social_security || 0);
                const gf = parseFloat(record.provident_fund || 0);
                const tax = parseFloat(record.personal_tax || 0);
                const other = parseFloat(record.other_amount || 0);
                return (abs + ss + gf + tax + other).toFixed(2);
              },
            },
          ],
        },
        {
          title: "应发数",
          dataIndex: "total_arrears_amount",
          key: "total_arrears_amount",
          align: "center",
          // customRender: (text, record) => {
          //   // 实发 = (基本工资+补助工资) - (缺勤+社保+公积金+个税)
          //   const base = parseFloat(record.base_salary || 0);
          //   const overtime = parseFloat(record.overtime_pay || 0);
          //   const abs = parseFloat(record.absence_deductions || 0);
          //   const ss = parseFloat(record.social_security || 0);
          //   const gf = parseFloat(record.provident_fund || 0);
          //   const tax = parseFloat(record.personal_tax || 0);
          //   const other = parseFloat(record.other_amount || 0);
          //   const yingfa = base + overtime; // 应发
          //   const yingkou = abs + ss + gf + tax + other; // 应扣
          //   return (yingfa - yingkou).toFixed(2);
          // },
        },
        {
          title: "实发数",
          dataIndex: "total_paid_amount",
          key: "total_paid_amount",
          align: "center",
        },
        {
          title: "付款凭证",
          key: "image",
          scopedSlots: { customRender: "image" },
        },
        {
          title: "操作",
          dataIndex: "action",
          // fixed: 'right',
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],

      searchForm: { page: 1, page_size: 16, is_void: 0 },
      // searchForm: { page: 1, page_size: 16,},
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},
      totalPaidAmount: 0,
    };
  },
  computed: {},
  methods: {
    initialize() {
      // var employee_id = this.$route.query.employeeId;
      this.searchForm["employee_type"] = "permanent";
      this.list();
      employeeOption({
        page_size: 999999,
        is_active: true,
        employee_type: "permanent",
      }).then((data) => {
        this.employeeItems = data.results;
      });
    },
    list() {
      this.loading = true;
      salaryRecordsList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;

          this.totalPaidAmount = data.results.reduce(
            (sum, item) => sum + parseFloat(item.total_paid_amount),
            0
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleMonthChange(date, dateString) {
      // 第2个参数 dateString 就是 "YYYY-MM" 格式的字符串
      if (dateString) {
        this.searchForm.payment_date = dateString;
      } else {
        this.searchForm.payment_date = null;
      }
      this.search(); // 你原先的search回调
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },

    showImages(images) {
      this.images = images;
      this.isModalVisible = true;
    },
    handleCancel() {
      this.isModalVisible = false;
    },

    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    viewPaymentOrders(payment_order, work_type) {
      // 跳转到查看付款单页面，可以传递客户ID

      var order_info = payment_order.payment_orders_item.map((item) => {
        return item.order_info;
      });
      order_info = order_info.filter((item) => item.work_type === work_type);
      let totalAmount = 0;
      for (let item of order_info) {
        totalAmount = NP.plus(totalAmount, item.salary_day);
      }
      order_info = [
        ...order_info,
        {
          id: "-1",
          isTotal: true,
          name: "",
          salary_day: totalAmount,
        },
      ];
      this.tableData = order_info;
      this.visible = true;
    },

    createPayment() {
      console.log("createPayment");
      this.$router.push({
        path: "/hrms/payment_salary_p_create",
        query: { employee_type: "permanent" },
      });
    },
    voidItem(item) {
      SalaryRecordVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },
  },

  mounted() {
    const { start_date, end_date, employee_type } = this.$route.query;
    console.log("start_date", start_date);
    if (start_date) {
      const date = new Date(start_date);
      const yearMonth = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}`;
      this.searchForm.payment_date = yearMonth;
      // this.radioValue = null;
    } else {
      const date = new Date(); // 获取当前时间
      const year = date.getFullYear(); // 获取年份
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份，getMonth() 返回的是 0-11 的月份，需要加 1 并格式化为两位数
      const yearMonth = `${year}-${month}`; // 拼接成 "YYYY-MM" 格式
      // console.log(yearMonth); // 输出当前年份和月份，如 "2025-02"

      this.searchForm.payment_date = yearMonth;
    }

    this.initialize();
  },
};
</script>

<style scoped>
.total {
  color: #a94442;
}
</style>